import React from 'react';
import styled from 'styled-components';
import { img_1, img_2, img_3, txt_1, txt_2, txt_3 } from '../images/PC';

const Wrapper = styled.div`
    width: 100vw;

    .title {
        padding-left: 25%;
        margin-top: 70px;
        font-size: 24px;
        line-height: 27px;
        font-weight: bold;
    }
`;

const ImgWrapper = styled.div`
    padding: 50px 17%;
    align-items: center;

    .icon {
        margin-left: 15%;
        width: 20%;
        height: 20%;
        max-width: 130px;
    }

    .txt {
        margin-left: 150px;
        height: 100px;
    }
`;

const PCSection2 = () => {
    return (
        <Wrapper>
            <div className='title'>애플리케이션 기능</div>

            <ImgWrapper>
                <div
                    style={{
                        display: 'flex',
                        borderBottom: '1px solid #e8e8e8',
                        alignItems: 'center',
                        padding: '50px',
                    }}
                >
                    <img className='icon' src={img_1} alt='' />
                    <img className='txt' src={txt_1} alt='' />
                </div>
                <div
                    style={{
                        display: 'flex',
                        borderBottom: '1px solid #e8e8e8',
                        alignItems: 'center',
                        padding: '50px',
                    }}
                >
                    <img className='icon' src={img_2} alt='' />
                    <img className='txt' src={txt_2} alt='' style={{ height: 170 }} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '50px',
                    }}
                >
                    <img className='icon' src={img_3} alt='' />
                    <img className='txt' src={txt_3} alt='' style={{ height: 210 }} />
                </div>
            </ImgWrapper>
        </Wrapper>
    );
};

export default PCSection2;
