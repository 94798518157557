import styled from 'styled-components';
import PCTop from './PC/PCTop';
import MobileTop from './Mobile/MobileTop';
import PCSection1 from './PC/PCSection1';
import PCSection2 from './PC/PCSection2';
import PCSection3 from './PC/PCSection3';
import PCSection4 from './PC/PCSection4';
import PCSection5 from './PC/PCSection5';
import PCFooter from './PC/PCFooter';
import MobileSection1 from './Mobile/MobileSection1';
import MobileSection2 from './Mobile/MobileSection2';
import MobileSection3 from './Mobile/MobileSection3';
import MobileSection4 from './Mobile/MobileSection4';
import MobileSection5 from './Mobile/MobileSection5';
import MobileFooter from './Mobile/MobileFooter';
import Banner from "./components/banner";
import Header from "./components/header";
import Body from "./components/body";
import Footer from "./components/footer";
import {useEffect, useState} from "react";

const Web = styled.div`
    width: 100vw;
    height: 100vh;

    @media (min-width: 0px) and (max-width: 679px) {
        display: none;
    }
`;

const Mobile = styled.div`
    width: 100vw;
    height: 100vh;

    @media (min-width: 680px) and (max-width: 20000px) {
        display: none;
    }
`;

function Main() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrolled, setScrolled] = useState(false);

    // Scroll 위치를 감지
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
        return () => {
            window.removeEventListener("scroll", updateScroll);
        };
    }, []);

    // scroll 위치가 100이하라면 투명한 배경색을 지정하고, 아니면 흰색을 지정한다.
    useEffect(() => {
        if (scrollPosition < 100) {
            setScrolled(false);
        } else {
            setScrolled(true);
        }
    }, [scrollPosition]);

    return (
        <>
            <Header scrolled={ scrolled }/>
            <Banner/>
            <Body />
            <Footer />
        </>
    );
    return (
        <>
            <Web>
                <PCTop/>
                <PCSection1/>
                <PCSection2/>
                <PCSection3/>
                <PCSection4/>
                <PCSection5/>
                <PCFooter/>
            </Web>
            <Mobile>
                <MobileTop/>
                <MobileSection1/>
                <MobileSection2/>
                <MobileSection3/>
                <MobileSection4/>
                <MobileSection5/>
                <MobileFooter/>
            </Mobile>
        </>
    );
}

export default Main;
