import React from 'react';
import styled from 'styled-components';
import { slogon, slogon_text, top1, top2 } from '../images/PC';

const Wrapper = styled.div`
    width: 100vw;
    background-color: #fcfcfc;
    align-items: center;
    display: flex;
    flex-direction: column;

    .icon {
        width: 10%;
        max-width: 218px;
        margin-top: 80px;
    }

    .slogon {
        margin-top: 20px;
        max-width: 500px;
        width: 23%;
    }

    .txt {
        max-width: 500px;
        width: 50%;
        margin: 50px 0 80px;
    }
`;

const Section1 = () => {
    return (
        <Wrapper>
            <img className='icon' src={top1} alt='' />
            <img className='slogon' src={top2} alt='' />
            <img className='txt' src={slogon_text} alt='' />
        </Wrapper>
    );
};

export default Section1;
