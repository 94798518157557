import React from 'react';
import styled from 'styled-components';
import { bg, txt, box_1, box_2, box_3, box_4 } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    position: relative;

    .bg {
        width: 100%;
    }
    .txt {
        position: absolute;
        top: 10%;
        left: 15%;
        width: 80%;
        transform: translate(-5%, -20%);
    }
`;

const ImgWrapper = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-84%, -30%);

    img {
        width: 170%;
        margin-bottom: 20px;
    }
`;
const MobileSection3 = () => {
    return (
        <Wrapper>
            <img className='bg' src={bg} />
            <img className='txt' src={txt} />

            <ImgWrapper>
                <img src={box_1} />
                <img src={box_2} />
                <img src={box_3} />
                <img src={box_4} />
            </ImgWrapper>
        </Wrapper>
    );
};

export default MobileSection3;
