const Body = () => {
    return (
        <div
            className={'flex flex-col items-center pt-[60px] pb-[60px] lg:pt-[100px] lg:pb-[100px] 2xl:pt-[140px] 2xl:pb-[140px] px-[16px] lg:px-0'}>
            <span className={'text-[30px] 2xl:text-[50px] font-bold'}>포아브와 함께하기</span>
            <div className={'h-[20px] lg:h-[70px]'}/>
            {/*  페이지 이동  */}
            <div className={'flex flex-col gap-y-[20px] lg:flex-row lg:gap-x-[36px] lg:w-full lg:px-32 xl:px-64'}>
                <div
                    className={'flex flex-col w-full px-[30px] pt-[40px] pb-10 lg:pb-16 rounded-3xl bg-05a6ff'}>
                    <div className={'flex flex-col gap-y-2 lg:gap-y-0'}>
                        <span className={'text-white text-[30px] 2xl:text-[60px] font-bold'}>ESG Mind Set!</span>
                        <span className={'text-white text-[20px] 2xl:text-[30px] font-light'}>걸음기부캠페인! 비대면봉사활동!</span>
                    </div>
                    <div className={'h-[100px] lg:h-24'}/>
                    <div className={ 'hover:-translate-y-1 transition duration-150' }>
                        <a className={'bg-white px-4 lg:px-[30px] py-2 lg:py-3 rounded-full text-[14px] 2xl:text-[20px] text-05a6ff'}
                           href={'https://foaveum.co.kr'} target={'_blank'}>바로가기</a>
                    </div>
                </div>
                <div
                    className={'flex flex-col w-full px-[30px] pt-[40px] pb-10 lg:pb-16 rounded-3xl bg-4d7e00'}>
                    <div className={'flex flex-col gap-y-2 lg:gap-y-0'}>
                        <div>
                            <span className={'text-white text-[30px] 2xl:text-[60px] font-medium'}>다회용기 순환</span>
                            <span className={'text-white text-[30px] 2xl:text-[60px] font-bold'}> 이벤저스</span>
                        </div>
                        <span className={'text-white text-[20px] 2xl:text-[30px] font-light'}>지속가능한 지구를 생각한다면 이제는 다회용기!</span>
                    </div>
                    <div className={'h-[100px] lg:h-24'}/>
                    <div className={ 'hover:-translate-y-1 transition duration-150' }>
                        <a className={'bg-white px-[30px] py-3 rounded-full text-[14px] 2xl:text-[20px] text-4d7e00'}
                           href={'https://esgconnect.co.kr'} target={'_blank'}>바로가기</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Body;