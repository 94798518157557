import {clsx} from "clsx";

const Header = ({ scrolled }) => {
    return (
        <div className={ clsx('fixed w-full h-[80px] lg:h-[103px] text-white z-10 flex justify-around items-center transition duration-500', [scrolled ? 'bg-white' : 'border-b border-3e3e3e'])}>
            {/* 로고 */}
            <img className={ 'h-10' } src={'/imgs/foav_logo.png'} alt={ '로고' } />
            {/* 관리자페이지 버튼 */}
            <a className={ clsx('rounded-full border-2 px-[16px] lg:px-[30px] py-[10px] transition duration-300', [scrolled ? 'text-black border-black hover:bg-black hover:text-white' : 'hover:bg-white hover:text-black']) } href={'https://admin.foav.co.kr'} target={'_blank'}>포아브 관리자</a>
        </div>
    );
}

export default Header;