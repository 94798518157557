import React from 'react';
import styled from 'styled-components';
import { slogon, slogon_txt, more } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    background-color: #fcfcfc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .slogon {
        margin-top: 50px;
        width: 50%;
    }

    .txt {
        margin: 30px 0 50px;
        width: 80%;
    }

    /* .more {
        width: 12%;
        margin: 30px 0;
    } */
`;

const MobileSection1 = () => {
    return (
        <Wrapper>
            <img className='slogon' src={slogon} alt='' />
            <img className='txt' src={slogon_txt} alt='' />
            {/* <img className='more' src={more} alt='' /> */}
        </Wrapper>
    );
};

export default MobileSection1;
