import React from 'react';
import styled from 'styled-components';
import { insta, footer, footer2, kakao, footer_logo } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    padding: 50px 0;
    display: flex;
    flex-direction: column;

    .logo {
        width: 35%;
        margin: 0 auto 30px;
    }

    .footer {
        width: 70%;
        margin: 0 auto 30px;
    }

    .footer2 {
        width: 90%;
        margin: 0 auto 30px;
    }

    .icon {
        width: 10%;
        margin-right: 20px;

        &:nth-child(2) {
            margin-right: 0;
        }
    }

    .category {
        margin-right: 15px;
        margin-bottom: 20px;
        text-decoration: none;
        color: black;
        font-weight: 500;
        font-size: 20px;

        &:nth-child(4) {
            margin-right: 0;
        }
    }

    @media all and (max-width: 580px) {
        .category {
            font-size: 15px;
        }
    }

    @media all and (max-width: 450px) {
        .category {
            font-size: 13px;
        }
    }

    @media all and (max-width: 370px) {
        .category {
            font-size: 11px;
        }
    }

    @media all and (max-width: 320px) {
        .category {
            font-size: 10px;
        }
    }
    @media all and (max-width: 300px) {
        .category {
            font-size: 7px;
        }
    }
`;

const MobileFooter = () => {
    return (
        <Wrapper>
            <img className='logo' src={footer_logo} />
            <img className='footer' style={{ width: '45%' }} src={footer} />
            <div style={{ display: 'flex', width: '95%', justifyContent: 'center', margin: '0 auto' }}>
                {/*<a href='' className='category'>*/}
                {/*    포아브 이용약관*/}
                {/*</a>*/}
                <a href='https://foav.co.kr/userPolicy.html' className='category'>
                    이용약관 및 개인정보처리방침
                </a>
                <a href='https://foav.co.kr/locPolicy.html' className='category'>
                    위치기반서비스
                </a>
                {/*<a href='' className='category'>*/}
                {/*    이용약관*/}
                {/*</a>*/}
            </div>
            <img className='footer2' src={footer2} />
            <div className='icons'>
                <img className='icon' src={insta} style={{ marginLeft: '38%' }} />
                <img className='icon' src={kakao} />
            </div>
        </Wrapper>
    );
};

export default MobileFooter;
