import React from 'react';
import { banner, logo, pc_text } from '../images/PC';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100vw;
    position: relative;

    a {
        text-decoration: none;
        color: #fff;
    }

    .banner {
        width: 100%;
    }

    .top {
        position: absolute;
        top: 20px;
        left: 20%;
        display: flex;
        justify-content: space-between;
        width: 70%;
        align-items: center;

        .logo {
            width: 170px;
        }

        .category {
            color: #fff;
            font-size: 18px;
            display: flex;

            .content {
                margin-right: 200px;
                font-weight: 600;
                font-size: 20px;
                font-weight: 600;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .text {
        position: absolute;
        width: 20%;
        top: 40%;
        left: 20%;
    }
`;

const PCTop = () => {
    return (
        <Wrapper>
            <img className='banner' src={banner} alt='' />
            <div className='top'>
                <img className='logo' src={logo} alt='' />
                <div className='category'>
                    <a href='https://admin.foav.co.kr' target='_blank'>
                        <div className='content'>관리자 페이지</div>
                    </a>
                </div>
            </div>
            <img className='text' src={pc_text} alt='' />
        </Wrapper>
    );
};

export default PCTop;
