import React from 'react';
import styled from 'styled-components';
import { img_1, txt_1, img_2, txt_2, img_3, txt_3 } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .txt {
        margin-top: 30px;
        font-size: 24px;
        font-weight: bold;
        line-height: 27px;
        text-align: center;
        margin-bottom: 50px;
    }

    .img1 {
        width: 20%;
        margin: 0 auto;
    }
    .txt1 {
        width: 30%;
        margin: 20px auto 0;
    }

    .img2 {
        width: 23%;
        margin: 50px auto 0;
    }
    .txt2 {
        width: 40%;
        margin: 20px auto 0;
    }
    .img3 {
        width: 23%;
        margin: 50px auto 0;
    }
    .txt3 {
        width: 75%;
        margin: 20px auto 80px;
    }
`;

const Line = styled.div`
    width: 90%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 60px auto 0;
`;

const MobileSection2 = () => {
    return (
        <Wrapper>
            <div className='txt'>애플리케이션 기능</div>
            <img className='img1' src={img_1} />
            <img className='txt1' src={txt_1} />
            <Line />
            <img className='img2' src={img_2} />
            <img className='txt2' src={txt_2} />
            <Line />
            <img className='img3' src={img_3} />
            <img className='txt3' src={txt_3} />
        </Wrapper>
    );
};

export default MobileSection2;
