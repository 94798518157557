import React from 'react';
import styled from 'styled-components';
import { footer, footer2, footer_logo, kakao, insta } from '../images/PC';

const Wrapper = styled.div`
    width: 100vw;
`;

const ImgWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 50px 15%;

    .category {
        text-decoration: none;
        color: black;
        font-size: 15px;
        font-weight: 600;
        margin: 15px 20px 5px 0;
    }

    .logo {
        width: 100px;
        margin-bottom: 20px;
    }

    .footer {
        width: 300px;
    }

    .footer2 {
        margin-top: 15px;
        width: 700px;
    }

    .icons {
        position: absolute;
        top: 80px;
        right: 15%;
        a {
            align-items: center;

            .insta {
                margin-right: 15px;
            }

            img {
                width: 30px;
                height: 30px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
`;

const Title = styled.div`
    font-weight: 700;
    margin-right: 15px;
    font-size: 15px;
    line-height: 1.75;
    color: #7b7b7b;
`;

const Content = styled.div`
    font-size: 15px;
    font-weight: 600;
    line-height: 1.75;
    color: #898989;
`;

const PCFooter = () => {
    return (
        <Wrapper>
            <ImgWrapper>
                <img className='logo' src={footer_logo} />
                <div style={{ display: 'flex' }}>
                    <Title>상호명</Title>
                    <Content>주식회사 포아브 (FOAV lnc.)</Content>
                </div>

                {/*<Content>경상북도 포항시 북구 장량중앙로 40, 210동 901호</Content>*/}

                <div style={{ display: 'flex' }}>
                    <Title>대표자명</Title>
                    <Content>박성집</Content>
                </div>

                <div style={{ display: 'flex' }}>
                    <Title>사업자등록번호</Title>
                    <Content>809-87-02726</Content>
                </div>

                <div style={{ display: 'flex' }}>
                    <Title>Email</Title>
                    <Content>foav20@naver.com</Content>
                </div>

                <div style={{ display: 'flex' }}>
                    <Title>고객센터</Title>
                    <Content>010 5904 4723</Content>
                </div>

                <div style={{ display: 'flex' }}>
                    {/*<a className='category' href='https://www.naver.com' target='_blank'>*/}
                    {/*    포아브 이용약관*/}
                    {/*</a>*/}
                    <a className='category' href='https://foav.co.kr/userPolicy.html' target='_blank'>
                        이용약관 및 개인정보처리방침
                    </a>
                    <a className='category' href='https://foav.co.kr/locPolicy.html' target='_blank'>
                        위치기반서비스
                    </a>
                    {/*<a className='category' href='https://www.naver.com' target='_blank'>*/}
                    {/*    이용약관*/}
                    {/*</a>*/}
                </div>
                <img className='footer2' src={footer2} />
                <div className='icons'>
                    <a href='https://www.instagram.com/foav_official' target='_blank'>
                        <img className='insta' src={insta} alt='' />
                    </a>
                    <a href='http://pf.kakao.com/_natxmT' target='_blank'>
                        <img src={kakao} alt='' />
                    </a>
                </div>
            </ImgWrapper>
        </Wrapper>
    );
};

export default PCFooter;
