import React from 'react';
import styled from 'styled-components';
import { bg, txt, box_1, box_2, box_3, box_4 } from '../images/PC';

const Wrapper = styled.div`
    width: 100vw;
    position: relative;

    .bg {
        width: 100%;
    }

    .txt {
        position: absolute;
        top: 22%;
        left: 50%;
        width: 25%;
        transform: translate(-50%, -50%);
    }
`;

const ImgWrapper = styled.div`
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -30%);

    img {
        width: 50%;
        max-width: 600px;
        margin-right: 30px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
`;

const PCSection3 = () => {
    return (
        <Wrapper>
            <img className='bg' src={bg} alt='' />
            <img className='txt' src={txt} alt='' />

            <ImgWrapper>
                <div style={{ display: 'flex' }}>
                    <img src={box_1} />
                    <img src={box_2} />
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <img src={box_3} />
                    <img src={box_4} />
                </div>
            </ImgWrapper>
        </Wrapper>
    );
};

export default PCSection3;
