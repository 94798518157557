import React from 'react';
import styled from 'styled-components';
import { app, appstore, android } from '../images/PC';

const Wrapper = styled.div`
    width: 100vw;
    background: #fff;
    height: 200px;
    display: flex;
    justify-content: space-between;

    .app {
        width: 170px;
        height: 60px;
        margin-left: 15%;
        margin-top: 60px;
    }

    .app2 {
        margin-right: 30px;

        a {
            align-items: center;

            .android {
                width: 100%;
                max-width: 200px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .app3 {
        .ios {
            width: 100%;
            max-width: 200px;
            &:hover {
                cursor: pointer;
            }
        }
    }
`;

const PCSection4 = () => {
    return (
        <Wrapper>
            <img className='app' src={app} />

            <div style={{ display: 'flex', marginRight: '15%', marginTop: '45px', marginLeft: '2%' }}>
                <div className='app2'>
                    <div style={{ textAlign: 'center', fontSize: 20, marginBottom: 5 }}>Android</div>
                    <a href='https://play.google.com/store/apps/details?id=com.rightcode.foav' target='_blank'>
                        <img className='android' src={android} />
                    </a>
                </div>

                <div className='app3'>
                    <div style={{ textAlign: 'center', fontSize: 20, marginBottom: 5 }}>ios</div>
                    <img className='ios' src={appstore} />
                </div>
            </div>
        </Wrapper>
    );
};

export default PCSection4;
