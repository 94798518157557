import React from 'react';
import styled from 'styled-components';
import { contact } from '../images/PC';
import { phone_bg } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    position: relative;

    .bg {
        max-height: 200px;
        width: 100%;
    }

    .contact {
        width: 240px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const PCSection5 = () => {
    return (
        <Wrapper>
            <img className='bg' src={phone_bg} alt='' />
            <img className='contact' src={contact} />
        </Wrapper>
    );
};

export default PCSection5;
