const Banner = () => {
    return (
        <div className={ 'relative flex justify-center items-center' }>
            <img className={'w-full h-[437px] lg:h-[800px] 2xl:h-[904px] object-cover'} src={'/imgs/banner_bg.png'} alt={'배너'}/>
            <div className={ 'absolute text-white flex flex-col items-center select-none' }>
                <span className={ 'text-[20px] lg:text-[30px] font-medium' }>생활속 ESG 포아브</span>
                <div className={ 'h-[30px]' } />
                <span className={ 'text-[30px] lg:text-[50px] 2xl:text-[80px] font-medium' }>기후 위기에 대응하기 위해</span>
                <span className={ 'text-[30px] lg:text-[50px] 2xl:text-[80px] font-bold' }>포아브와 함께해주세요</span>
            </div>
        </div>
    );
}

export default Banner;