import React from 'react';
import styled from 'styled-components';
import { appstore, app, android } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;

    .app {
        width: 60%;
        margin: 50px auto;
    }

    a {
        align-items: center;
    }

    .android {
        width: 60%;
        margin: 30px 20% 50px;
    }
    .ios {
        width: 60%;
        margin: 30px 20% 50px;
    }
`;

const MobileSection4 = () => {
    return (
        <Wrapper>
            <img className='app' src={app} />
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '30px',
                }}
            >
                Android
            </div>
            <a href='https://play.google.com/store/apps/details?id=com.rightcode.foav' target='_blank'>
                <img className='android' src={android} />
            </a>
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '30px',
                }}
            >
                ios
            </div>
            <a href='' target='_blank'>
                <img className='ios' src={appstore} />
            </a>
        </Wrapper>
    );
};

export default MobileSection4;
