const Footer = () => {
    return (
        <div className={ 'border-t border-d5d5d5 pt-[70px] pb-[54px] flex flex-col justify-center items-center' }>
            <img className={ 'h-8 lg:h-12 2xl:h-20' } src={ '/imgs/foav_logo.png' } alt={ '로고' } />
            <div className={ 'h-8' } />
            <div className={ 'flex flex-col gap-y-2 text-919191 items-center lg:text-[16px] text-[12px]' }>
                <span>대표 : 박성집 ㅣ 주소 : 경북 포항시 남구 대이로5번길 14-3. 202호 포아브</span>
                <span>협업 문의 : 010 5904 4723, foav20@naver.com</span>
            </div>
        </div>
    );
}

export default Footer;