import React from 'react';
import styled from 'styled-components';
import { main_txt, banner, menu, logo } from '../images/Mobile';

const Wrapper = styled.div`
    width: 100vw;
    position: relative;

    a {
        text-decoration: none;
        color: #fff;
    }

    .banner {
        width: 100%;
    }

    .top {
        align-items: center;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 20px;

        .logo {
            max-width: 130px;
            margin-left: 20px;
        }

        .category {
            color: #fff;
            font-size: 18px;
            display: flex;

            .content {
                margin-right: 20px;
                font-weight: 600;
                font-size: 16px;
                font-weight: 600;

                &:hover {
                    cursor: pointer;
                }
            }
        }
        .menu {
            width: 30px;
            height: 20px;
            margin-right: 20px;
        }
    }

    .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
    }
`;

const MobileTop = () => {
    return (
        <Wrapper>
            <img className='banner' src={banner} />
            <div className='top'>
                <img className='logo' src={logo} />
                <div className='category'>
                    <a href='https://admin.foav.co.kr' target='_blank'>
                        <div className='content'>관리자 페이지</div>
                    </a>
                </div>
            </div>
            <img className='txt' src={main_txt} alt='' />
        </Wrapper>
    );
};

export default MobileTop;
